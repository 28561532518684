<template>
  <div class="row">
    <div class="col-12">
      <h2 v-if="!this.$route.params.id">Cadastro de Escola</h2>
      <h2 v-if="this.$route.params.id">Atualização de Escola</h2>

      <Card>
        <h4>Dados</h4>
        <form @submit.prevent="saveSchool()">
          <div class="row">
            <div class="col-md-3">
              <base-input
                v-model="form.name"
                type="text"
                placeholder="Nome"
                label="Nome"
              >
                <HasErrorForm
                  :form="form"
                  field_name="name"
                  slot="helperText"
                />
              </base-input>
            </div>
            <div class="col-md-3 dropdown-top">
              <base-dropdown
                title-classes="btn btn-warning btn-block"
                titleTag="div"
                :title="
                  selectedProvince
                    ? `Estado: ${selectedProvince.name}`
                    : 'Estado'
                "
              >
                <a
                  v-for="province in provinces"
                  :key="province.id"
                  class="dropdown-item"
                  href="#"
                  @click="selectProvince(province)"
                  >{{ province.name }}</a
                >
              </base-dropdown>
            </div>
            <div class="col-md-3 dropdown-top">
              <base-dropdown
                title-classes="btn btn-warning btn-block"
                titleTag="div"
                :title="
                  selectedCity ? `Cidade: ${selectedCity.name}` : 'Cidade'
                "
              >
                <a v-if="!selectedProvince" class="dropdown-item" href="#"
                  >Selecione um Estado</a
                >
                <a
                  v-if="selectedProvince && cities.length <= 0"
                  class="dropdown-item"
                  href="#"
                  >Nenhuma cidade encontrada</a
                >
                <span v-if="selectedProvince && cities.length > 0">
                  <a
                    v-for="city in cities"
                    :key="city.id"
                    class="dropdown-item"
                    href="#"
                    @click="selectCity(city)"
                    >{{ city.name }}</a
                  >
                </span>
              </base-dropdown>

              <HasErrorForm
                :form="form"
                field_name="city_id"
                slot="helperText"
              />
            </div>
            <div class="col-md-3 dropdown-top">
              <base-dropdown
                title-classes="btn btn-warning btn-block"
                titleTag="div"
                :title="
                  selectedDistrict
                    ? `Bairro: ${selectedDistrict.name}`
                    : 'Bairro'
                "
              >
                <a v-if="!selectedCity" class="dropdown-item" href="#"
                  >Selecione uma Cidade</a
                >
                <a
                  v-if="selectedCity && districts.length <= 0"
                  class="dropdown-item"
                  href="#"
                  >Nenhum Bairro encontrado</a
                >
                <span v-if="selectedCity && districts.length > 0">
                  <a
                    v-for="district in districts"
                    :key="district.id"
                    class="dropdown-item"
                    href="#"
                    @click="selectDistrict(district)"
                    >{{ district.name }}</a
                  >
                </span>
              </base-dropdown>
              <HasErrorForm
                :form="form"
                field_name="district_id"
                slot="helperText"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <base-radio name="active" class="col-md-3" v-model="isActive">
                Ativo
              </base-radio>

              <base-radio name="inactive" class="col-md-3" v-model="isActive">
                Inativo
              </base-radio>
            </div>
            <div></div>
          </div>
          <div class="text-right">
            <base-button native-type="submit" type="warning">
              Salvar
            </base-button>
          </div>
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import Form from 'vform'
import Card from '@/components/Cards/Card'
import { BaseDropdown } from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'
import BaseRadio from '@/components/Inputs/BaseRadio'
import HasErrorForm from '@/components/HasErrorForm'

export default {
  name: 'NewEditSchool',
  data: () => ({
    form: false,
    isActive: 'active',
    provinces: [],
    cities: [],
    districts: [],
    selectedProvince: false,
    selectedCity: false,
    selectedDistrict: false
  }),

  methods: {
    resetLocation () {
      this.selectedCity = false
      this.form.city_id = null
      this.form.district_id = null
    },
    async selectProvince (province) {
      this.selectedProvince = province
      const {
        data: { cities }
      } = await this.$http.get(`cities/${province.id}`)
      this.cities = cities
    },
    async selectCity (city) {
      this.selectedDistrict = false
      this.selectedCity = city
      this.form.city_id = city.id

      const {
        data: { districts }
      } = await this.$http.get(`districts/${city.id}`)
      this.districts = districts
    },
    selectDistrict (district) {
      this.selectedDistrict = district
      this.form.district_id = district.id
    },
    async saveSchool () {
      try {
        if (this.school_id) {
          this.form.id = this.school_id
        }

        this.form.is_active = this.isActive === 'active' ? 1 : 0

        const {
          data: { data }
        } = await this.form.post('/schools')

        this.$notify({
          type: 'primary',
          message: this.school_id ? 'Escola atualizada!' : 'Escola Criada!',
          icon: 'tim-icons icon-bell-55'
        })

        this.school_id = data.id
        this.$router.push({ path: '/schools' })
      } catch (error) {
        //
      }
    }
  },
  async created () {
    const {
      data: { provinces }
    } = await this.$http.get('provinces')
    this.provinces = provinces

    if (this.$route.params.id !== undefined) {
      this.school_id = this.$route.params.id

      const {
        data: { data }
      } = await this.$http.get(`/schools/show/${this.$route.params.id}`)

      this.form = new Form({
        name: data.name
      })

      this.isActive = parseInt(data.is_active) ? 'active' : 'inactive'

      await this.selectProvince(
        this.provinces.find(
          p => p.id === parseInt(data.district.city.province_id)
        )
      )
      await this.selectCity(
        this.cities.find(c => c.id === parseInt(data.district.city_id))
      )
      await this.selectDistrict(
        this.districts.find(c => c.id === parseInt(data.district_id))
      )
    } else {
      this.form = new Form()

      await this.selectProvince(this.provinces.find(p => p.initials === 'PR')) // Paraná
      await this.selectCity(this.cities.find(c => c.ibge_code === '4108304')) // Foz do Iguaçu
    }
  },

  components: { Card, BaseInput, BaseDropdown, BaseRadio, HasErrorForm }
}
</script>

<style lang="css">
.dropdown-top {
  margin-top: 25px;
}
</style>
